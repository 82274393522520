<template>
  <div class="invoice_mgr">
    <query-form
      class="content_block"
      label-width="120px"
      size="small"
      label-position="right"
      @search="getList('init')"
      @reset="handleReset"
      :keepOpenedRow="2"
    >
      <el-form-item label="到货状态：" :span="24">
        <check-tag
          v-model="advancedForm.arrivalSts"
          @change="() => getList('init')"
        >
          <check-tag-item
            :value="i.value"
            :key="i.value"
            v-for="i in executionStatusGroup"
            :label="`${i.label}(${i.count || 0})`"
          ></check-tag-item>
        </check-tag>
      </el-form-item>
      <el-form-item label="销售订单编号：">
        <el-input
          v-model.trim="advancedForm.orderCode"
          clearable
          placeholder="请输入销售订单编号"
        ></el-input>
      </el-form-item>
      <el-form-item label="销售合同号：">
        <el-input
          v-model.trim="advancedForm.pcCode"
          clearable
          placeholder="请输入销售合同号"
        ></el-input>
      </el-form-item>
      <el-form-item label="收货人">
        <el-input
          v-model.trim="advancedForm.receivingName"
          clearable
          placeholder="请输入收货人"
        ></el-input>
      </el-form-item>
    </query-form>
    <TableWithPagination
      class="content_block"
      :columnList="columnList"
      :tableData="dataList"
      :pagination.sync="pagination"
      @size-change="getList"
      @current-change="getList"
      @sort-change="getList"
    />
  </div>
</template>
<script>
import TableWithPagination, {
  TablePaginationMixin,
} from '@/components/common/TableWithPagination';
import { queryPage, queryStateInfo, cancel } from '@/api/invoice';

export default {
  name: 'InvoiceMgr',
  mixins: [TablePaginationMixin],
  components: {
    TableWithPagination,
  },
  data () {
    return {
      dataList: [],
      advancedForm: {
        arrivalSts: '',
      },
      executionStatusGroup: [
        {
          label: '全部',
          value: '',
          en: 'allArrivalNum',
          count: 0,
        },
        {
          label: '待收货',
          value: '1',
          en: 'toBeReceivedNum',
          count: 0,
        },
        {
          label: '部分收货',
          value: '2',
          en: 'parArrivalNum',
          count: 0,
        },
        {
          label: '已收货',
          value: '3',
          en: 'receiveGoodNum',
          count: 0,
        },
        {
          label: '已取消',
          value: '4',
          en: 'cancelledNum',
          count: 0,
        },
      ],
      columnList: [
        {
          label: '到货状态',
          prop: 'arrivalSts',
          tooltip: true,
          render: (h, { row }) => {
            let status = '--';

            for (let v of this.executionStatusGroup) {
              if (v.value * 1 === row.arrivalSts) {
                status = v.label;
              }
            }

            return h('span', status);
          },
        },
        {
          label: '发货商品',
          prop: 'goodsNameInfo',
          tooltip: true,
        },
        {
          label: '发货单号',
          prop: 'arrivalCode',
          tooltip: true,
        },
        {
          label: '物流公司',
          prop: 'logistics',
          tooltip: true,
        },
        {
          label: '物流单号',
          prop: 'oddNumbers',
          tooltip: true,
        },
        {
          label: '客户名称',
          prop: 'unit',
          tooltip: true,
        },
        {
          label: '销售订单号',
          prop: 'orderCode',
          tooltip: true,
        },
        {
          label: '销售合同号',
          prop: 'pcCode',
          tooltip: true,
        },
        {
          label: '收货人',
          prop: 'receivingName',
          tooltip: true,
        },
        {
          label: '更新时间',
          prop: 'lastUpdateTime',
          tooltip: true,
        },
        {
          label: '操作',
          minWidth: '80px',
          render: (h, { row }) => {
            let actions = [h('span', {
              class: 'contract-option',
              on: {
                click: () => {
                  this.operate('detail', row);
                },
              },
            }, '查看')];

            if (row.arrivalSts === 1) { // 1-> 待收货
              actions.push(h('span', {
                class: 'contract-option',
                on: {
                  click: () => {
                    this.operate('cancel', row);
                  },
                },
              }, '取消'));
            }

            return actions;
          },
        },
      ],
    };
  },
  created () {
    this.getList();
    this.getCount();
  },
  methods: {
    getList (type) {
      if (type === 'init') {
        this.pagination = {
          total: 0,
          currentPage: 1,
          pageSize: 10,
        };
      }

      let { pageSize, currentPage: pageNum } = this.pagination;
      let orderBy = [
        {
          orderByProperty: 'lastUpdateTime',
          orderByType: 'DESC',
        },
      ];

      queryPage({
        ...this.advancedForm,
        orderBy,
        pageSize,
        pageNum,
      }).then(({ body }) => {
        this.dataList = body.list;
        this.pagination.total = body.total;
      });
    },
    getCount () {
      queryStateInfo().then(({ body }) => {
        for (let v of this.executionStatusGroup) {
          v.count = body[v.en];
        }
      });
    },
    handleReset () {
      for (let key in this.advancedForm) {
        this.advancedForm[key] = '';
      }

      this.pagination = {
        total: 0,
        currentPage: 1,
        pageSize: 10,
      };

      this.getList();
    },
    operate (type, row) {
      if (type === 'cancel') {
        this.$confirm(`确认取消发货[${row.arrivalCode}]吗？`, '确认取消', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          cancel({id: row.id}).then(() => {
            this.$message({
              type: 'success',
              message: '取消成功!',
            });
            this.getList('init');
          });
        });
      } else {
        this.$router.push({
          path: '/invoiceMgr/detail',
          query: {
            id: row.id,
          },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.invoice_mgr {
  /deep/ .contract-option {
    color: #02a7f0;
    cursor: pointer;
    padding: 0 0.25em;
  }

  .el-range-editor--small.el-input__inner {
    width: 100%;
  }
}
</style>
